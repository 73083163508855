import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoArrowDown, LazySvgoArrowDownLight, LazySvgoArrowLeft, LazySvgoArrowRight, LazySvgoBurger, LazySvgoCheck, LazySvgoCheckCircle, LazySvgoCircle, LazySvgoEWallet, LazySvgoEye, LazySvgoEyeClosed, LazySvgoMail, LazySvgoMailOpen, LazySvgoPdf, LazySvgoQrCode, LazySvgoTimes } from '#components'
const lazyGlobalComponents = [
  ["SvgoArrowDown", LazySvgoArrowDown],
["SvgoArrowDownLight", LazySvgoArrowDownLight],
["SvgoArrowLeft", LazySvgoArrowLeft],
["SvgoArrowRight", LazySvgoArrowRight],
["SvgoBurger", LazySvgoBurger],
["SvgoCheck", LazySvgoCheck],
["SvgoCheckCircle", LazySvgoCheckCircle],
["SvgoCircle", LazySvgoCircle],
["SvgoEWallet", LazySvgoEWallet],
["SvgoEye", LazySvgoEye],
["SvgoEyeClosed", LazySvgoEyeClosed],
["SvgoMail", LazySvgoMail],
["SvgoMailOpen", LazySvgoMailOpen],
["SvgoPdf", LazySvgoPdf],
["SvgoQrCode", LazySvgoQrCode],
["SvgoTimes", LazySvgoTimes],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
