import validate from "/build/node_modules/nuxt/dist/pages/runtime/validate.js";
import sentry_45global from "/build/middleware/sentry.global.ts";
import manifest_45route_45rule from "/build/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  sentry_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "password-set": () => import("/build/middleware/password_set.ts"),
  "password-unset": () => import("/build/middleware/password_unset.ts"),
  "two-factor-confirmed": () => import("/build/middleware/two_factor_confirmed.ts"),
  "two-factor-unconfirmed": () => import("/build/middleware/two_factor_unconfirmed.ts"),
  unverified: () => import("/build/middleware/unverified.ts"),
  verified: () => import("/build/middleware/verified.ts"),
  "sanctum:auth": () => import("/build/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.auth.js"),
  "sanctum:guest": () => import("/build/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.guest.js")
}