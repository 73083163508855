import revive_payload_client_4sVQNw7RlN from "/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_HQNyLQlVwt from "/build/node_modules/nuxt3-meta-pixel/dist/runtime/plugin.mjs";
import plugin_client_UYiXMU8ZyN from "/build/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_vfCt6Q18Tc from "/build/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import date_LyjcPwWLrp from "/build/plugins/date.ts";
import environment_6jcoV7IFIi from "/build/plugins/environment.ts";
import mitt_S0QU5gJPTl from "/build/plugins/mitt.ts";
import recaptcha_85gNSCNFUU from "/build/plugins/recaptcha.ts";
import sentry_3AyO8nEfhE from "/build/plugins/sentry.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_HQNyLQlVwt,
  plugin_client_UYiXMU8ZyN,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_vfCt6Q18Tc,
  date_LyjcPwWLrp,
  environment_6jcoV7IFIi,
  mitt_S0QU5gJPTl,
  recaptcha_85gNSCNFUU,
  sentry_3AyO8nEfhE
]